import { VueConstructor } from "vue";
import AlertDialog from "@/plugins/alert/AlertDialog.vue";

const AlertType = ["success", "info", "warning", "error"] as const;
type AlertType = typeof AlertType[number];

declare module "vue/types/vue" {
  interface Vue {
    $alert(type: AlertType, message: string, timeout?: number): Promise<void>;
  }
}

export const $alert = (type: AlertType, message: string, timeout?: number) => {
  return new Promise(resolve => {
    new AlertDialog({
      propsData: { message, type, timeout, close: () => resolve() }
    });
  });
};

export const alert = {
  install(Vue: VueConstructor) {
    Vue.prototype.$alert = $alert;
  }
};

export { AlertType };
export const useAlert = () => {
  return { $alert };
};
