import { VueConstructor } from "vue";
import ConfirmDialog from "@/plugins/confirm/content/ConfirmDialog.vue";
import DeleteDialog from "@/plugins/confirm/content/DeleteDialog.vue";

declare module "vue/types/vue" {
  interface Vue {
    $confirm(message: string): Promise<boolean>;
    $deleteConfirm(message?: string): Promise<boolean>;
  }
}

export const $confirm = (message: string) => {
  return new Promise<boolean>(resolve => {
    new ConfirmDialog({
      propsData: {
        message,
        success: () => resolve(true),
        failure: () => resolve(false)
      }
    });
  });
};

const deleteMessage = "削除します。\nよろしいですか？";
export const $deleteConfirm = (message = deleteMessage) => {
  return new Promise<boolean>(resolve => {
    new DeleteDialog({
      propsData: {
        message,
        success: () => resolve(true),
        failure: () => resolve(false)
      }
    });
  });
};

export const confirm = {
  install(Vue: VueConstructor) {
    Vue.prototype.$confirm = $confirm;
    Vue.prototype.$deleteConfirm = $deleteConfirm;
  }
};

export const useConfirm = () => ({
  $confirm,
  $deleteConfirm
});
