import { VueConstructor } from "vue";
import Loading from "@/plugins/loading/Loading.vue";

declare module "vue/types/vue" {
  interface Vue {
    $loading(): void;
    $unloading(): void;
  }
}

const queue: boolean[] = [];

new Loading({ propsData: { queue } });

export const $loading = () => queue.push(true);
export const $unloading = () => queue.pop();

export const loading = {
  install(Vue: VueConstructor) {
    Vue.prototype.$loading = $loading;
    Vue.prototype.$unloading = $unloading;
  }
};
