import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify/lib";
import { confirm } from "@/plugins/confirm/confirm";
import { alert } from "@/plugins/alert/alert";
import { loading } from "@/plugins/loading/loading";
import { paths } from "./plugins/api/paths";
import { api } from "./plugins/api/api";
import { dialog } from "@/plugins/dialog/dialog";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

//オリジナルプラグイン
Vue.use(confirm);
Vue.use(alert);
//Vue.use(loading);
Vue.use(paths);
Vue.use(api);
Vue.use(dialog);

Vue.config.productionTip = false;

declare module "vue/types/vue" {
  interface Vue {
    $error(message: string, title?: string | null): Promise<boolean>;
    $showDialog<T = boolean>(dialog: any, props?: Record<any, any>): Promise<T>;
  }
}

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: h => h(App)
}).$mount("#app");
