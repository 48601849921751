
import Vue, { PropType } from "vue";
import Vuetify, {
  VDialog,
  VIcon,
  VBtn,
  VCard,
  VToolbar,
  VToolbarTitle,
  VCardText,
  VCardActions,
  VSpacer,
  VDivider
} from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default Vue.extend({
  name: "ConfirmDialog",
  vuetify: new Vuetify(),
  components: {
    VDialog,
    VIcon,
    VBtn,
    VCard,
    VToolbar,
    VToolbarTitle,
    VCardText,
    VCardActions,
    VSpacer,
    VDivider
  },
  props: {
    message: {
      type: String,
      required: true
    },
    success: {
      type: Function as PropType<() => void>,
      required: true
    },
    failure: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  data() {
    return {
      isActive: false
    };
  },
  methods: {
    ok() {
      this.success();
      this.destroy();
    },
    cancel() {
      this.failure();
      this.destroy();
    },
    destroy() {
      this.isActive = false;

      // 即座にdomを削除するとtransitionする前に消えてしまうので、200ms待つ
      setTimeout(() => {
        if (document.body.contains(this.$el)) {
          document.body.removeChild(this.$el);
        }
        this.$destroy();
      }, 200);
    }
  },
  created() {
    //呼び出されたときにマウント
    const el = document.createElement("div");
    document.body.appendChild(el);
    this.$mount(el);

    this.isActive = true;
  }
});
